import React from "react"
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import Layout from "../components/layout"
import 'prismjs/themes/prism-okaidia.css';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { siteMetadata } = site
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title} — {site.siteMetadata.title}</title>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>
      <div className="blog-post-container">
        <article className="single-post">
          {!frontmatter.thumbnail && (
            <div className="post-thumbnail" style={{ backgroundImage: `url('/assets/images/world-map.svg')` }}>
              <div className="post-details">
                <h1 className="h2">{frontmatter.title}</h1>
                <div className="blog-meta">
                  <p className="blog-category">{frontmatter.category}</p> <span class="dot"></span> <time>{frontmatter.date}</time>
                </div>
              </div>
            </div>
          )}
          {!!frontmatter.thumbnail && (
            <div className="post-thumbnail" style={{backgroundImage: `url(${frontmatter.thumbnail})`}}>
              <div className="post-details">
                <h1 className="h2">{frontmatter.title}</h1>
                <div className="blog-meta">
                  <p className="blog-category">{frontmatter.category}</p> <span class="dot"></span> <time>{frontmatter.date}</time>
                </div>
              </div>
            </div>
          )}
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
        category
      }
    }
  }
`
